import { WEB_PRIMARY_FONT, SECONDARY_FONT } from '@pff-consumer/core-ui'
import type { TextStyle } from 'react-native'
import { NativeUiTheme } from '../../styles/theme'
import { useFontStyles } from '../../styles/create-use-fonts'

/**
 * 🚨🚨🚨NEVER REFERENCE THESE TOKENS OUTSIDE THE SIBLING TEXT COMPONENT 🚨🚨🚨
 * Font weight naming
 * 300 - Light
 * 400 - Regular
 * 500 - Medium
 * 600 - SemiBold
 * 700/800 - Bold
 * 900 - XBold
 */

export type TextTokens = {
  [key: string]: TextStyle
}

export const useGenerateTokens = (theme: NativeUiTheme): TextTokens => {
  const TextColor = {
    color: theme.colors.webTextColorDefault,
  }

  // Primary Fonts (ROBOTO)

  const XXSmallPrimaryBold: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '800'),
    fontSize: theme.calcUnit(8),
    lineHeight: theme.calcUnit(12),
  }

  const XXSmallItalicPrimaryBold: TextStyle = {
    ...XXSmallPrimaryBold,
    fontStyle: 'italic',
  }

  const XSmallPrimaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '400'),
    fontSize: theme.calcUnit(10),
    lineHeight: theme.calcUnit(12),
  }

  const XSmallPrimaryMedium: TextStyle = {
    ...XSmallPrimaryRegular,
    fontWeight: '500',
  }

  const XSmallPrimarySemiBold: TextStyle = {
    ...XSmallPrimaryRegular,
    fontWeight: '600',
  }

  const XSmallPrimaryBold: TextStyle = {
    ...XSmallPrimaryRegular,
    fontWeight: '700',
  }

  const XSmallItalicPrimaryBold: TextStyle = {
    ...XSmallPrimaryBold,
    fontStyle: 'italic',
  }

  const XSmallPrimaryXBold: TextStyle = {
    ...XSmallPrimaryRegular,
    fontWeight: '900',
  }

  const SmallPrimaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '400'),
    fontSize: theme.calcUnit(12),
    lineHeight: theme.calcUnit(16),
  }

  const SmallItalicPrimaryRegular: TextStyle = {
    ...SmallPrimaryRegular,
    lineHeight: theme.calcUnit(18),
    fontStyle: 'italic',
  }

  const SmallPrimaryMedium: TextStyle = {
    ...SmallPrimaryRegular,
    fontWeight: '500',
  }

  const SmallPrimaryBold: TextStyle = {
    ...SmallPrimaryRegular,
    fontWeight: '700',
  }

  const SmallPrimaryXBold: TextStyle = {
    ...SmallPrimaryRegular,
    fontWeight: '900',
  }

  const RegularPrimaryLight: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '300'),
    fontSize: theme.calcUnit(14),
    lineHeight: theme.calcUnit(24),
  }

  const RegularPrimaryRegular: TextStyle = {
    ...RegularPrimaryLight,
    fontWeight: '400',
  }

  const RegularPrimaryMedium: TextStyle = {
    ...RegularPrimaryLight,
    fontWeight: '500',
  }

  const RegularPrimaryBold: TextStyle = {
    ...RegularPrimaryLight,
    fontWeight: '700',
  }

  const RegularPrimaryXBold: TextStyle = {
    ...RegularPrimaryLight,
    fontWeight: '900',
  }

  const MediumPrimaryLight: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '300'),
    fontSize: theme.calcUnit(16),
    lineHeight: theme.calcUnit(20),
  }

  const MediumPrimaryRegular: TextStyle = {
    ...MediumPrimaryLight,
    fontWeight: '400',
  }

  const MediumPrimaryMedium: TextStyle = {
    ...MediumPrimaryLight,
    fontWeight: '500',
  }

  const MediumPrimarySemiBold: TextStyle = {
    ...MediumPrimaryLight,
    fontWeight: '600',
  }

  const MediumPrimaryBold: TextStyle = {
    ...MediumPrimaryLight,
    fontWeight: '700',
  }

  const MediumPrimaryXBold: TextStyle = {
    ...MediumPrimaryLight,
    fontWeight: '900',
  }

  const XMediumPrimaryRegular: TextStyle = {
    ...TextColor,
    color: theme.colors.contentColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '400'),
    fontSize: theme.calcUnit(18),
    lineHeight: theme.calcUnit(30),
  }

  const XMediumItalicPrimaryRegular: TextStyle = {
    ...XMediumPrimaryRegular,
    fontStyle: 'italic',
  }

  const XMediumPrimaryBold: TextStyle = {
    ...XMediumPrimaryRegular,
    fontWeight: '800',
  }

  const XMediumItalicPrimaryBold: TextStyle = {
    ...XMediumPrimaryRegular,
    fontWeight: '800',
    fontStyle: 'italic',
  }

  const LargePrimaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '400'),
    fontSize: theme.calcUnit(20),
    lineHeight: theme.calcUnit(32),
  }

  const LargePrimaryBold: TextStyle = {
    ...LargePrimaryRegular,
    fontWeight: '700',
  }

  const LargePrimaryXBold: TextStyle = {
    ...LargePrimaryRegular,
    fontWeight: '900',
  }

  const XLargePrimaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '400'),
    fontSize: theme.calcUnit(24),
    lineHeight: theme.calcUnit(32),
  }

  const XLargePrimaryBold: TextStyle = {
    ...XLargePrimaryRegular,
    fontWeight: '700',
  }

  const XXLargePrimaryBold: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '700'),
    fontSize: theme.calcUnit(28),
    lineHeight: theme.calcUnit(34),
  }

  const XXLargePrimaryXBold: TextStyle = {
    ...XXLargePrimaryBold,
    fontWeight: '900',
  }

  const JumboPrimaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '400'),
    fontSize: theme.calcUnit(32),
    lineHeight: theme.calcUnit(32),
  }

  const JumboPrimaryBold: TextStyle = {
    ...JumboPrimaryRegular,
    fontWeight: '700',
  }

  const JumboPrimaryXBold: TextStyle = {
    ...JumboPrimaryRegular,
    fontWeight: '800',
  }

  // Secondary Fonts (PFF SANS)

  const XSmallSecondaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(SECONDARY_FONT, '400'),
    fontSize: theme.calcUnit(10),
    lineHeight: theme.calcUnit(12),
  }

  const XSmallSecondaryMedium: TextStyle = {
    ...XSmallSecondaryRegular,
    fontWeight: '500',
  }

  const XSmallSecondarySemiBold: TextStyle = {
    ...XSmallSecondaryRegular,
    fontWeight: '600',
  }

  const XSmallItalicSecondarySemiBold: TextStyle = {
    ...XSmallSecondaryRegular,
    fontWeight: '600',
    fontStyle: 'italic',
  }

  const XSmallSecondaryBold: TextStyle = {
    ...XSmallSecondaryRegular,
    fontWeight: '700',
  }

  const XSmallItalicSecondaryXBold: TextStyle = {
    ...XSmallSecondaryRegular,
    fontWeight: '900',
    fontStyle: 'italic',
  }

  const SmallSecondaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(SECONDARY_FONT, '400'),
    fontSize: theme.calcUnit(12),
    lineHeight: theme.calcUnit(14),
  }

  const SmallSecondaryMedium: TextStyle = {
    ...SmallSecondaryRegular,
    fontWeight: '500',
  }

  const SmallItalicSecondaryMedium: TextStyle = {
    ...SmallSecondaryRegular,
    fontWeight: '500',
    fontStyle: 'italic',
  }

  const SmallSecondarySemiBold: TextStyle = {
    ...SmallSecondaryRegular,
    fontWeight: '600',
  }

  const SmallItalicSecondarySemiBold: TextStyle = {
    ...SmallSecondaryRegular,
    fontWeight: '600',
    fontStyle: 'italic',
  }

  const RegularSecondaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(SECONDARY_FONT, '400'),
    fontSize: theme.calcUnit(14),
    lineHeight: theme.calcUnit(20),
  }

  const RegularSecondaryMedium: TextStyle = {
    ...RegularSecondaryRegular,
    fontWeight: '500',
  }

  const RegularSecondarySemiBold: TextStyle = {
    ...RegularSecondaryRegular,
    fontWeight: '600',
  }

  const RegularItalicSecondaryBold: TextStyle = {
    ...RegularSecondaryRegular,
    fontWeight: '700',
    fontStyle: 'italic',
  }

  const MediumSecondaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(SECONDARY_FONT, '400'),
    fontSize: theme.calcUnit(16),
    lineHeight: theme.calcUnit(20),
  }

  const MediumSecondarySemiBold: TextStyle = {
    ...MediumSecondaryRegular,
    fontWeight: '600',
  }

  const MediumItalicSecondarySemiBold: TextStyle = {
    ...MediumSecondarySemiBold,
    fontStyle: 'italic',
  }

  const MediumSecondaryBold: TextStyle = {
    ...MediumSecondaryRegular,
    fontWeight: '700',
  }

  const LargeSecondarySemiBold: TextStyle = {
    ...TextColor,
    ...useFontStyles(SECONDARY_FONT, '600'),
    fontSize: theme.calcUnit(20),
    lineHeight: theme.calcUnit(24),
  }

  const LargeItalicSecondarySemiBold: TextStyle = {
    ...LargeSecondarySemiBold,
    fontStyle: 'italic',
  }

  const LargeSecondaryBold: TextStyle = {
    ...LargeSecondarySemiBold,
    fontWeight: '700',
  }

  const XLargeSecondaryBold: TextStyle = {
    ...TextColor,
    ...useFontStyles(SECONDARY_FONT, '700'),
    fontSize: theme.calcUnit(24),
    lineHeight: theme.calcUnit(30),
  }

  const XLargeItalicSecondaryBold: TextStyle = {
    ...XLargeSecondaryBold,
    fontWeight: '800',
    fontStyle: 'italic',
  }

  const XXLargeSecondaryBold: TextStyle = {
    ...TextColor,
    ...useFontStyles(SECONDARY_FONT, '700'),
    fontSize: theme.calcUnit(28),
    lineHeight: theme.calcUnit(36),
  }

  const JumboSecondarySemiBold: TextStyle = {
    ...TextColor,
    ...useFontStyles(SECONDARY_FONT, '600'),
    fontSize: theme.calcUnit(32),
    lineHeight: theme.calcUnit(38),
  }

  const JumboSecondaryBold: TextStyle = {
    ...JumboSecondarySemiBold,
    fontWeight: '700',
  }

  const XJumboPrimaryRegular: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '400'),
    fontSize: theme.calcUnit(40),
    lineHeight: theme.calcUnit(36),
  }

  const XJumboPrimaryBold: TextStyle = {
    ...TextColor,
    ...useFontStyles(WEB_PRIMARY_FONT, '700'),
    fontSize: theme.calcUnit(40),
    lineHeight: theme.calcUnit(36),
  }

  const XJumboSecondaryBold: TextStyle = {
    ...TextColor,
    ...useFontStyles(SECONDARY_FONT, '700'),
    fontSize: theme.calcUnit(40),
    lineHeight: theme.calcUnit(40),
  }

  return {
    // Primary Fonts (ROBOTO)
    XXSmallPrimaryBold,
    XXSmallItalicPrimaryBold,
    XSmallPrimaryRegular,
    XSmallPrimaryMedium,
    XSmallPrimarySemiBold,
    XSmallPrimaryBold,
    XSmallItalicPrimaryBold,
    XSmallPrimaryXBold,
    SmallPrimaryRegular,
    SmallItalicPrimaryRegular,
    SmallPrimaryMedium,
    SmallPrimaryBold,
    SmallPrimaryXBold,
    RegularPrimaryLight,
    RegularPrimaryRegular,
    RegularPrimaryMedium,
    RegularPrimaryBold,
    RegularPrimaryXBold,
    MediumPrimaryLight,
    MediumPrimaryRegular,
    MediumPrimaryMedium,
    MediumPrimarySemiBold,
    MediumPrimaryBold,
    MediumPrimaryXBold,
    XMediumPrimaryRegular,
    XMediumItalicPrimaryRegular,
    XMediumPrimaryBold,
    XMediumItalicPrimaryBold,
    LargePrimaryRegular,
    LargePrimaryBold,
    LargePrimaryXBold,
    XLargePrimaryRegular,
    XLargePrimaryBold,
    XXLargePrimaryBold,
    XXLargePrimaryXBold,
    JumboPrimaryRegular,
    JumboPrimaryBold,
    JumboPrimaryXBold,
    // Secondary Fonts (PFF Sans)
    XSmallSecondaryRegular,
    XSmallSecondaryMedium,
    XSmallSecondarySemiBold,
    XSmallItalicSecondarySemiBold,
    XSmallSecondaryBold,
    XSmallItalicSecondaryXBold,
    SmallSecondaryRegular,
    SmallSecondaryMedium,
    SmallItalicSecondaryMedium,
    SmallSecondarySemiBold,
    SmallItalicSecondarySemiBold,
    RegularSecondaryRegular,
    RegularSecondaryMedium,
    RegularSecondarySemiBold,
    RegularItalicSecondaryBold,
    MediumSecondaryRegular,
    MediumSecondarySemiBold,
    MediumItalicSecondarySemiBold,
    MediumSecondaryBold,
    LargeSecondarySemiBold,
    LargeItalicSecondarySemiBold,
    LargeSecondaryBold,
    XLargeSecondaryBold,
    XLargeItalicSecondaryBold,
    XXLargeSecondaryBold,
    JumboSecondarySemiBold,
    JumboSecondaryBold,
    XJumboPrimaryRegular,
    XJumboPrimaryBold,
    XJumboSecondaryBold,
  }
}
